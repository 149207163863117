import { LyricVideoSetlistItem } from "@/models/LyricVideoSetlistItem";
import mitt, { Emitter } from "mitt";

export const emitter: Emitter<{
  languageUpdated: void;
  hideAllModals: void;
  songListUpdated: string;
  alwaysShowId: string;
  deactivateAllPreviews: void;
  readyToRunNonBlockingScripts: void;
  resetFontStylesConfirmed: void;
  resetAllSettingsConfirmed: void;
  youtubeVideoEnded: void;
  LCPEvent: void;
  disconnectIntegrationConfirmed: void;
  reloadSetlist: void;
  updatedSetlistDetails: {
    setListName: string;
    setListNote: string;
    setListDate: string;
    videos: LyricVideoSetlistItem[];
  };
}> = mitt();
