import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import App from "./App.vue";
import { pinia } from "./config/pinia";
import router from "./config/router";
import { RaygunFunctions, raygunInit } from "./config/RayGun";

// hide potential messages of unsupported browser that was done on index.html
const element = document.querySelector("#UnsupportedBrowserMessage");
if (element) {
  (element as HTMLDivElement).style.display = "none";
}
const app = createApp(App);
const head = createHead();

app.use(pinia).use(router).use(i18n).use(head).mount("#app");

useProfileStore();

const appStore = useAppStore();
if (appStore.browserSupported) {
  app.config.errorHandler = RaygunFunctions.handleVueError;
}

const darkMode = useDarkModeStore();
darkMode.init();

emitter.on("readyToRunNonBlockingScripts", () => {
  import("./styles/main.css");

  if (!isBot()) {
    raygunInit();

    (window as any).loadGoogleAnalytics();
  } else {
    useDebug().log("Bot detected");
  }
});
